import { useRouter } from 'src/hooks/useRouter'

interface LinkProps extends React.PropsWithChildren {
  action?: string
  className?: string
  disabled?: boolean
  hash?: string
  multiAction?: string
  newTab?: boolean
  onClick?: () => void | Promise<void>
  stopPropagation?: boolean
  doNotTrack?: boolean
  synthetic?: boolean
  to: string
  tracking?: object
}

export const Link: React.FC<LinkProps> = ({
  action = obe.events.user_link,
  children,
  className,
  disabled = false,
  hash,
  multiAction,
  newTab = false,
  onClick = () => undefined,
  stopPropagation = false,
  doNotTrack = false,
  synthetic = true,
  to,
  tracking = {},
}) => {
  const router = useRouter()

  return React.createElement(
    defaultStyle,
    {
      className,
      href: to,
      onClick: async (event) => {
        if (stopPropagation) event.stopPropagation()

        event.preventDefault()
        if (disabled) return

        await onClick()

        if (multiAction && !doNotTrack) {
          obe.analytics.track(multiAction, {
            from: window.location.pathname,
            ...tracking,
            to,
          })
        }

        !doNotTrack &&
          obe.analytics.track(action, {
            from: window.location.pathname,
            ...tracking,
            to,
          })

        if (newTab || to?.startsWith('http') || event.metaKey) {
          window.open(to, '_blank')
        } else if (synthetic) {
          router.push(to)
        } else if (hash) {
          if (to === window.location.pathname) {
            document.getElementById(hash)?.scrollIntoView()
            window.location.hash = `#${hash}`
          } else {
            window.location.href = `${to}#${hash}`
          }
        } else {
          window.location.href = to
        }
      },
      rel: newTab ? 'noreferrer noopener' : undefined,
      target: newTab ? '_blank' : undefined,
    },
    children
  )
}

const defaultStyle = styled.a`
  color: var(--nearBlack);
  :hover {
    color: var(--nearBlack);
  }
`
