export type BreakpointName = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'

export type Breakpoint<T = string> = { [key in `${BreakpointName}AndUp`]: T } & {
  [key in `${BreakpointName}AndDown`]: T
} & { [key in `${BreakpointName}`]: T }

// Keep in sync with Bootstrap 4 variables in app/javascript/styles/_variables.scss
export const breakpoints: { name: BreakpointName; min: number; max: number }[] = [
  { name: 'xsmall', min: 0, max: 575 },
  { name: 'small', min: 576, max: 767 },
  { name: 'medium', min: 768, max: 1024 },
  { name: 'large', min: 1025, max: 1199 },
  { name: 'xlarge', min: 1200, max: 9999 },
]

export const breakPointMins = breakpoints.reduce((acc, { name, min }) => {
  acc[name] = min
  return acc
}, {} as Record<BreakpointName, number>)

export const breakpoint = ((): Breakpoint => {
  const media: Record<string, string> = {}
  breakpoints.map(({ name, min, max }) => {
    media[name] = `@media (min-width: ${min}px) and (max-width: ${max}px)`
    media[`${name}AndUp`] = `@media (min-width: ${min}px)`
    media[`${name}AndDown`] = `@media (max-width: ${max}px)`
  })
  return media as Breakpoint
})()

export const copy = {
  medium: css`
    font-size: 16px;
    letter-spacing: 0.72px;
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 18px;
    }
  `,
  large: css`
    font-size: 18px;
    letter-spacing: 0.72px;
  `,
  xlarge: css`
    font-size: 30px;
    letter-spacing: 0.72px;
  `,
  // willa copy styles
  headerXlarge: css`
    font-size: 32px;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 1;
  `,
  title: css`
    font-size: 34px;
    font-weight: 300;
    letter-spacing: 0.875px;
    line-height: 34px;
  `,
  textSmall: css`
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.325px;
  `,
}

const baseButton = css`
  border: 1px solid transparent;
  display: block;
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.25rem;
  padding: 15px 32px;
  letter-spacing: 0.3px;
  text-align: center;
  transition: all 0.15s ease-in-out;
  user-select: none;
  border-radius: 2em;
  :hover {
    text-decoration: none;
  }
`

export const button = {
  primary: css`
    ${baseButton}
    background-color: var(--yellow);
    border-color: var(--yellow);
    color: #212529;
    :hover {
      background-color: #faf143;
      border-color: #faf143;
    }
    :focus {
      outline: 0;
    }
  `,
  newPrimary: css`
    ${baseButton}
    text-transform: none;
    background-color: var(--persianBlue);
    color: var(--white);
    :hover {
      background-color: #5329c9;
      font-weight: 450;
      color: var(--white);
    }
    :focus {
      outline: 0;
    }
  `,
  secondary: css`
    ${baseButton}
    border: 2px solid transparent;
    background-color: white;
    border-color: #212529;
    color: #212529;
    :hover {
      background-color: var(--nearBlack);
      color: white;
    }
    :focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
      outline: 0;
    }
  `,
  newSecondary: css`
    ${baseButton}
    padding: 14px 31px;
    background: var(--white);
    color: var(--neutrals700);
    border: 2px solid var(--neutrals500);
    border-radius: 100px;
    :not(:disabled) {
      border: 2px solid var(--black);
      color: var(--black);
      background: var(--white);
      font-weight: 500;
    }
    :focus {
      outline: 0;
    }
    @media (hover: hover) {
      :hover {
        border: 2px solid var(--neutrals600);
        background: var(--white);
        color: var(--neutrals600);
        outline: 0;
      }
    }
  `,
  muted: css`
    ${baseButton}
    background-color: white;
    border-color: #c7c7c7;
    color: #979797;
    :hover {
      background-color: #c7c7c7;
      color: #212529;
    }
    :focus {
      box-shadow: 0 0 0 0.2rem hsla(0, 0%, 78%, 0.5);
      outline: 0;
    }
  `,
  transparent: css`
    ${baseButton}
    background-color: transparent;
    border-color: white;
    color: white;
    letter-spacing: 0.85px;
    line-height: 16px;
    :hover {
      background-color: #fff648;
      border-color: #fff63b;
    }
    :focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 216, 100, 0.5);
      outline: 0;
    }
  `,
}

export const mixin = {
  cover: css`
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  `,
  obeGradient: css`
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--white)),
      linear-gradient(to right, #f4d7d3, #baf9ff 49%, #dfd9fe);
    background-repeat: no-repeat;
    background-size: 100% 50vh;
  `,
  cardBoxShadow: css`
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
  `,
  flexSpaceBetween: css`
    display: flex;
    justify-content: space-between;
  `,
  flexSpaceAround: css`
    display: flex;
    justify-content: space-around;
  `,
  flexSpaceEvenly: css`
    display: flex;
    justify-content: space-evenly;
  `,
  flexCenterHorizontal: css`
    display: flex;
    justify-content: center;
  `,
  flexCenterVertical: css`
    display: flex;
    align-items: center;
  `,
  flexCenterBoth: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  flexStatic: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
  spaceChildrenHorizontal: (margin: string) => css`
    & > * {
      margin-right: ${margin};
    }
    & > *:last-child {
      margin-right: 0;
    }
  `,
  spaceChildrenVertical: (margin: string) => css`
    > :not(:last-child) {
      margin-bottom: ${margin};
    }
  `,
}
